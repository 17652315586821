import React, { useState } from 'react';

import Box from '@mui/material/Box';
import { Button, OutlinedInput } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const AddCardButton = ({ columnId, handleAdd }) => {

  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState('');

  return (
    <Box
      sx={{
        padding: 2,
        color: '#6a6a6a'
      }}
    >
      {!showForm ? (
        <Button
          disableElevation
          disableTouchRipple
          size="small"
          fullWidth
          startIcon={<AddRoundedIcon fontSize="small" />}
          onClick={(e) => {
            setTitle('');
            setShowForm(true);
          }}
          sx={{
            padding: 0,
            justifyContent: 'flex-start',
            borderRadius: '8px',
            textTransform: 'none',
            fontSize: '1rem',
            color: '#6a6a6a',
            ':hover': {
              backgroundColor: 'transparent'
            }
          }}>
          Adicionar cartão
        </Button>
      ) : (
        <>
          <OutlinedInput
            fullWidth
            placeholder="Insira um título"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            sx={{
              borderRadius: '8px',
              backgroundColor: '#fff',
              '& .MuiInputBase-input': {
                padding: '8px 16px'
              }
            }} />
          <Box sx={{
            display: 'flex',
            marginTop: 2
          }}>
            <Button
              disableElevation
              variant="contained"
              onClick={(e) => {
                handleAdd(columnId, title);
                setTitle('');
                setShowForm(false);
              }}
              startIcon={<AddRoundedIcon fontSize="small" />}
              sx={{
                borderRadius: '8px',
                textTransform: 'none'
              }}>
              Adicionar cartão
            </Button>
            <Button
              disableElevation
              onClick={(e) => { setShowForm(false); }}
              sx={{
                marginLeft: 2,
                borderRadius: '8px',
                textTransform: 'none',
                color: '#6a6a6a'
              }}>Cancelar</Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default AddCardButton;