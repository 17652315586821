import React, { useState } from 'react';

import Sidebar from './Sidebar';
import SidebarMobile from './SidebarMobile';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';

import styles from '../../styles/menu/Navbar.module.css';

const windowCheckFn = () => {
  return window.innerWidth < 600;
};

export default function Navbar({ user }) {
  const [profileEl, setProfileEl] = useState(null);
  const [state, setState] = useState(true);
  const [windowCheck, setWindowCheck] = useState(false);

  setInterval(() => {
    setWindowCheck(windowCheckFn());
  }, 1500);

  const handleMenu = event => {
    setProfileEl(event.currentTarget);
  };

  const handleClose = () => {
    setProfileEl(null);
  };

  const handleLogout = () => {
    window.location.href = '/logout/';
  };

  const toggleDrawer = isOpen => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(isOpen);
  };

  const handleChpass = () => {
    window.location.href = '/alterar-senha';
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='static' color='default'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={toggleDrawer(true)}
            className={styles.menuButton}
          >
            <MenuIcon />
          </IconButton>

          {windowCheck ? (
            <SidebarMobile
              user={user}
              state={state}
              toggleDrawer={toggleDrawer}
            />
          ) : (
            <Sidebar user={user} state={state} toggleDrawer={toggleDrawer} />
          )}

          <Typography
            variant='h6'
            component='div'
            sx={{ flexGrow: 1 }}
          ></Typography>

          <div>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleMenu}
              color='inherit'
              className={styles.profileButton}
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={profileEl}
              sx={{ mt: '35px', ml: '-10px' }}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(profileEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleChpass}>Trocar Senha</MenuItem>
              <MenuItem onClick={handleLogout}>Sair</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
