import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styles from './styles.module.css';


function Banner() {
  return (
    <div className={styles.banner}>
      <div>
        <img
          src={'/banner-image.png'}
          width="200px"
        />
      </div>

      <div className={styles.content}>
        <h1>Bem-vindo ao sistema ToGov!</h1>
        <p>
          Seus indicadores sempre atualizados!
          Explore todos os recursos clicando no menu
          (<span className={styles.menuIcon}><MenuIcon /></span>)
          ao lado.
        </p>
        <a href="https://wa.me/553195407950" className={styles.btn}>
          <WhatsAppIcon />
          <span>Entre em contato</span>
        </a>
      </div>
    </div>
  );
}

export default Banner;