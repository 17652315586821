import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import ListDepartament from './ListDepartament';
import ListGeneral from './ListGeneral';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SignalCellularAltRoundedIcon from '@mui/icons-material/SignalCellularAltRounded';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';

import ListBoards from './ListBoards';
import ListExternalities from './ListExternalities';
import { useEffect } from 'react';
import useToken from '../app/useToken';
import HOST from '../app/host';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

import styles from '../../styles/menu/Sidebar.module.css';

export default function Sidebar({ user, state, toggleDrawer }) {
  const { dashboards, departaments, boards, is_manager } = user;

  const navigate = useNavigate();
  const { token } = useToken();
  const [showPaineis, setShowPaineis] = useState(false);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState([false, false, false, false]);
  const [externalities, setExternalities] = useState([]);
  const [showBudget, setShowBudget] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    fetch(`${HOST}/api/companys/${user.company}/`, requestOptions)
      .then(response => response.json())
      .then(async data => {
        setShowBudget(data.show_budget);
        const value = [];
        await data.externalities_dashboards.forEach(element => {
          fetch(`${HOST}/api/v1/externalities/${element}/`, requestOptions)
            .then(response => response.json())
            .then(data => value.push(data));
        });
        setExternalities(value);
      });
  }, []);

  return (
    <Drawer
      open={state}
      onClose={toggleDrawer(false)}
      className={styles.drawer}
    >
      <Box className={styles.sidebar} role='presentation'>
        <Box
          className={user.logo_bg_white ? styles.logoBoxWhite : styles.logoBox}
        >
          <img src={`/media/${user.logo_url}`} width='100%' />
        </Box>

        <Typography
          component='p'
          variant='span'
          fontSize={13}
          className={styles.listTitle}
        >
          geral
        </Typography>

        <ListGeneral
          toggleDrawer={toggleDrawer}
          showPaineis={showPaineis}
          handleClick={e => {
            setShowPaineis(state => !state);
          }}
          checked={checked}
          handleChecked={e => {
            toggleDrawer(false)(e);
            if (!checked) {
              setChecked(state => !state);
              navigate('/exibicao-paineis');
            } else {
              setChecked(state => !state);
              navigate('/');
            }
          }}
          isManager={is_manager}
        />

        <Divider />

        <Typography
          component='p'
          variant='span'
          fontSize={13}
          className={styles.listTitle}
        >
          Módulos
        </Typography>

        {departaments.length > 0 && (
          <Box className={styles.listItem}>
            <ListItemButton
              onClick={() => setOpen([!open[0], false, false])}
              // className={styles.listItem3SmallListBtn}
            >
              <ListItemIcon>
                <SignalCellularAltRoundedIcon sx={{ fontSize: 25 }} />
              </ListItemIcon>
              <ListItemText
                primary={'Indicadores'}
                className={styles.listItemText2}
              />
              {open[0] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <ListDepartament
              openParent={open[0]}
              departaments={departaments}
              dashboards={dashboards}
              toggleDrawer={toggleDrawer}
            />
          </Box>
        )}

        {user.show_tax_requirements ? (
          <>
            <ListItemButton onClick={() => setOpen([false, !open[1], false])}>
              <ListItemIcon>
                <PriorityHighRoundedIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Externalidades'}
                className={styles.listItemText}
              />
              {open[1] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <ListExternalities
              openParent={open[1]}
              items={externalities}
              toggleDrawer={toggleDrawer}
            />
          </>
        ) : (
          <Link to='/contratacao' onClick={toggleDrawer(false)}>
            <ListItem
              key={'Externalidades'}
              disablePadding
              // className={styles.listItem3SmallListBtn}
            >
              <ListItemButton>
                <ListItemIcon>
                  <PriorityHighRoundedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={'Externalidades'}
                  className={styles.listItemText}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        {boards.length > 0 && (
          <>
            <ListItemButton onClick={() => setOpen([false, false, !open[2]])}>
              <ListItemIcon>
                <TaskAltIcon />
              </ListItemIcon>
              <ListItemText
                primary={'Quadros'}
                className={styles.listItemText}
              />
              {open[2] ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            <ListBoards
              boards={boards}
              openParent={open[2]}
              toggleDrawer={toggleDrawer}
            />
          </>
        )}

        {showBudget ? (
          <Link to='/orcamento' onClick={toggleDrawer(false)}>
            <ListItem key={'Orçamento'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PriceCheckIcon sx={{ fontSize: 27.5 }} />
                </ListItemIcon>
                <ListItemText
                  primary={'Orçamento'}
                  className={styles.listItemText}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <Link to='/contratacao' onClick={toggleDrawer(false)}>
            <ListItem key={'Orçamento'} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <PriceCheckIcon sx={{ fontSize: 27.5 }} />
                </ListItemIcon>
                <ListItemText
                  primary={'Orçamento'}
                  className={styles.listItemText}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        )}
      </Box>
    </Drawer>
  );
}
