import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Card from './Card';
import Box from '@mui/material/Box';

const CardList = ({ el, ind, handleClickCard }) => {
  return (
    <Droppable droppableId={`${el.id}`} type="CARD">
      {(provided, snapshot) => (
        <Box
          sx={{
            flexGrow: 1,
            minHeight: '80px',
            overflowY: 'auto',
            padding: '8px 16px'
          }}
          ref={provided.innerRef}
          {...provided.droppableProps}>
          {el && el.cards && el.cards.map((item, index) => (
            <Card key={item.id} item={item} index={index} ind={ind} handleClick={handleClickCard} />
          ))}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );
};

export default CardList;