import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import HOST from '../components/app/host';
import useToken from '../components/app/useToken';

import Board from '../components/kanban/Board';

import Container from '@mui/material/Container';

const Kanban = () => {

  const { id } = useParams();

  const { token } = useToken();

  const [board, setBoard] = useState(null);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    fetch(`${HOST}/api/v1/kanban/boards/${id}/`, requestOptions)
      .then(response => response.json())
      .then(data => setBoard(data));
  }, [id]);

  return (
    <Container maxWidth={false}>
      <Board data={board} />
    </Container>
  );
};

export default Kanban;