import React from 'react';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import styles from '../../styles/menu/Sidebar.module.css';

export default function ListDashboardMobile({
  openParent,
  dashboards,
  toggleDrawer
}) {
  return (
    <>
      {dashboards.map(dashboard => {
        return (
          <Collapse
            key={dashboard.id}
            in={openParent}
            timeout='auto'
            unmountOnExit
          >
            <List component='div' disablePadding className={styles.smallList}>
              <Link
                to={`paineis/${dashboard.id}`}
                onClick={toggleDrawer(false)}
                className={styles.listItem2SmallListBtn}
              >
                <ListItemButton className={styles.listItemBtn}>
                  <ListItemText
                    primary={dashboard.title}
                    className={styles.listItemText}
                  />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        );
      })}
    </>
  );
}
