import React from 'react';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import styles from '../../styles/menu/Sidebar.module.css';

const ListBoards = ({ boards, openParent, toggleDrawer }) => {
  if (Array.isArray(boards))
    return boards.map(item => (
      <Collapse
        key={item.id}
        in={openParent}
        timeout="auto"
        unmountOnExit
        sx={{ marginLeft: '35px' }}        
      >
        <List component="div" disablePadding>
          <Link to={`quadros/${item.id}`} onClick={toggleDrawer(false)}>
            <ListItemButton sx={{ pl: 4 }}>
              <ListItemText primary={item.title} className={styles.listItemText} />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
    ));
  else return null;

};

export default ListBoards;