import React, { useState } from 'react';
import { Box, ButtonBase, CardActions, Checkbox, Divider, IconButton, Input, InputBase, LinearProgress, OutlinedInput, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const AddCheckButton = ({ handleAdd }) => {

  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState('');

  return !showForm ? (
    <Button
      disableElevation
      size="small"
      startIcon={<AddRoundedIcon fontSize="small" />}
      onClick={(e) => { setShowForm(true); }}
      sx={{
        textTransform: 'none',
        fontWeight: 600
      }}>
      Adicionar item
    </Button>) : (
    <Box sx={{
      display: 'flex',
      width: '100%',
      alignItems: 'center'
    }}>
      <OutlinedInput
        placeholder="Insira um título"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        sx={{
          borderRadius: '8px',
          backgroundColor: '#fff',
          flexGrow: 1,
          '& .MuiInputBase-input': {
            padding: '8px 16px'
          }
        }} />
      <Button
        disableElevation
        variant="contained"
        onClick={(e) => {
          handleAdd(title);
          setTitle('');
          setShowForm(false);
        }}
        sx={{
          borderRadius: '8px',
          marginLeft: 2,
          textTransform: 'none'
        }}>
        Adicionar
      </Button>
      <Button
        disableElevation
        onClick={(e) => { setShowForm(false); }}
        sx={{
          marginLeft: 2,
          borderRadius: '8px',
          textTransform: 'none',
          color: '#6a6a6a'
        }}>Cancelar</Button>
    </Box>
  );
};

export default AddCheckButton;