import React, { useState } from 'react';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import styles from '../../styles/components/ui/FullScreenBtn.module.css';
import { useEffect } from 'react';

function FullScreenBtn() {
  const [showFullScreenIcon, setShowFullScreenIcon] = useState(false);
  const [initialScreenHeight, setInitialScreenHeight] = useState(0);
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {    
    setInitialScreenHeight(window.screen.availHeight);    
    setInterval(() => {
      setScreenHeight(window.screen.availHeight);
    }, 1000);    
  }, []);

  useEffect(() => {    
    setTimeout(() => {
      if (screenHeight <= initialScreenHeight) {        
        close();
      }      
    }, 250);
  }, [screenHeight]);

  function toggleFullScreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
      open();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      close();
    }
  }

  const open = () => {
    const bodyHTML = document.querySelector('body');
    const headerHTML = document.querySelector('header');
    bodyHTML.style.backgroundColor = '#fff';
    bodyHTML.style.marginTop = '40px';
    headerHTML.style.display = 'none';
    setShowFullScreenIcon(true);
  };

  const close = () => {
    const bodyHTML = document.querySelector('body');
    const headerHTML = document.querySelector('header');
    bodyHTML.style.backgroundColor = '#F9FAFC';
    bodyHTML.style.marginTop = '0px';
    headerHTML.style.display = 'block';
    setShowFullScreenIcon(false);
  };

  return (
    <button onClick={toggleFullScreen} className={styles.fullScreenBtn}>
      {showFullScreenIcon ? <FullscreenExitIcon /> : <FullscreenIcon />}
    </button>
  );
}

export default FullScreenBtn;
