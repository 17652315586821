import React, { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import styles from '../styles/pages/BudgetPage.module.css';
import useToken from '../components/app/useToken';
import HOST from '../components/app/host';

const getFormattedDate = schedule => {
  const month =
    schedule.getMonth() < 10
      ? `0${schedule.getMonth()}`
      : schedule.getMonth().toString();

  const day =
    schedule.getDate() < 10
      ? `0${schedule.getDate()}`
      : schedule.getDate().toString();

  return `${month}${day}`.split('');
};

function BudgetPage2(props) {
  const { token } = useToken();
  const fortyFiveDays = 3.888e9; // 45 days
  const [scheduleDate, setScheduleDate] = useState();
  const [date, setDate] = useState(['0', '0', '0', '0']);
  const [yearBtnList, setYearBtnList] = useState([]);
  const [activeYearBtn, setActiveYearBtn] = useState(0);
  const [activeGenerateBtn, setActiveGenerateBtn] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    fetch(`${HOST}/api/companys/loa/`, requestOptions)
      .then(response => response.json())
      .then(data => {
        const today = new Date();
        const expirationDate = new Date(data.loaExpirationDate);
        const schedule = new Date(expirationDate - today);
        setScheduleDate(schedule);
        setDate(getFormattedDate(schedule));

        console.log(data);

        const yearList = [];
        data.years.forEach(year => {
          if (!yearList.includes(year)) yearList.push(year);
        });
        const firstYear = yearList.at(0);
        const lastYear = data.years.at(data.years.length - 1) + 1;
        yearList.push(lastYear);

        setActiveYearBtn(firstYear);
        setYearBtnList(yearList);

        setAllFiles(data.files);
        const fileList = [];
        data.files.forEach(item => {
          if (item.year == firstYear) fileList.push(item.file);
        });
        setFiles(fileList);
      });
  }, []);

  const selectFiles = year => {
    const fileList = [];
    allFiles.forEach(item => {
      if (item.year == year) fileList.push(item.file);
    });
    setFiles(fileList);
  };

  const activeYearHandler = year => {
    const lastYear = yearBtnList.at(yearBtnList.length - 1);
    if (fortyFiveDays - scheduleDate.getTime() >= 0 && year == lastYear) {
      setActiveGenerateBtn(true);
    } else setActiveGenerateBtn(false);

    selectFiles(year);

    yearBtnList.forEach(item => item === year && setActiveYearBtn(year));
  };

  const onDownload = (file, fileName) => {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = file;
    link.click();
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Container maxWidth="xl">
          <h2 style={{ margin: '50px 0 15px 0' }}>ORÇAMENTO ANUAL</h2>
          <Box
            sx={{
              marginTop: 1,
              padding: 2,
              boxShadow: 1,
              borderRadius: 1,
              maxWidth: 600
            }}
          >
            <Box className={styles.yearBtnGroup}>
              {yearBtnList.map(year => (
                <Button
                  key={year}
                  className={activeYearBtn == year ? styles.active : ''}
                  variant="outlined"
                  onClick={() => activeYearHandler(year)}
                >
                  {year}
                </Button>
              ))}
            </Box>

            <Box sx={{ mt: 5 }}>
              {files.map((file, index) => {
                const lastOne = file.split('/').length - 1;
                const fileName = file.split('/')[lastOne];
                return (
                  <Button
                    key={fileName + index}
                    onClick={() => onDownload(`/media/${file}`, fileName)}
                    variant="outlined"
                    color="primary"
                    sx={{ margin: 1 }}
                  >
                    <DownloadIcon sx={{ marginRight: 1 }} />
                    <strong>{fileName}</strong>
                  </Button>
                );
              })}
            </Box>

            <div style={{ marginTop: 50 }}>
              <Button
                type="submit"
                variant="contained"
                disabled={!activeGenerateBtn}
              >
                Gerar
              </Button>
            </div>
          </Box>
        </Container>
      </div>

      <div className={styles.timeDiv}>
        <div>
          <h1>RESTAM:</h1>
          {date && (
            <div className={styles.time}>
              <div>
                <div style={{ display: 'flex' }}>
                  <div className={styles.box}>{date.at(0)}</div>
                  <div className={styles.box}>{date.at(1)}</div>
                </div>
                <div className={styles.title}>Mês</div>
              </div>

              <div>
                <div style={{ display: 'flex' }}>
                  <div className={styles.box}>{date.at(2)}</div>
                  <div className={styles.box}>{date.at(3)}</div>
                </div>
                <div className={styles.title}>Dia</div>
              </div>
            </div>
          )}
          <p>para o prazo de entrega da LOA</p>
        </div>
      </div>
    </div>
  );
}

export default BudgetPage2;
