/* eslint-disable indent */
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import CardList from './CardList';
import Box from '@mui/material/Box';
import { ButtonBase, Divider, Input } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import AddCardButton from './AddCardButton';

import styles from '../../styles/kanban/Column.module.css';


const Column = ({ el, ind, handleRename, handleAdd, handleClickMenu, handleClickCard }) => {
  const [title, setTitle] = useState(el.title);

  return (
    <Draggable
      draggableId={`col-${el.id}`}
      index={ind}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}>
          <Box className={styles.column}>
            <Box className={styles.header}
              {...provided.dragHandleProps}>
              <Input sx={{
                fontWeight: 600,
                borderRadius: '8px',
                ':hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)'
                },
                '& .MuiInputBase-input:focus': {
                  backgroundColor: '#fff',
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: 2
                }
              }}
                value={title}
                onChange={(e) => { setTitle(e.target.value); }}
                onBlur={(e) => { handleRename(ind, title); }}
                disableUnderline />
              <Box>
                <ButtonBase onClick={(e) => { handleClickMenu(e, el); }}>
                  <MoreHorizIcon sx={{ color: '#707C90' }} />
                </ButtonBase>
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: '0 0 8px 8px',
                backgroundColor: '#EBECF0'
              }}>
              <CardList el={el} ind={ind} handleClickCard={handleClickCard} />
              <Divider />
              <AddCardButton columnId={ind} handleAdd={handleAdd} />
            </Box>
          </Box>
        </div>
      )}
    </Draggable>
  );
};

export default Column;