import React, { useState } from 'react';

import { Box, Button, OutlinedInput } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import styles from '../../styles/kanban/AddColumnButton.module.css';

const AddColumnButton = ({ handleAddColumn }) => {

  const [showForm, setShowForm] = useState(false);
  const [title, setTitle] = useState('');

  return (
    <Box className={styles.lastColumn}>
      <Box className={styles.content}>
        <Box className={styles.btnWrapper}>
          {!showForm ? (
            <Button
              className={styles.addButton}
              disableTouchRipple
              disableElevation
              size="small"
              fullWidth
              onClick={(e) => {
                setTitle('');
                setShowForm(true);
              }}
              startIcon={<AddRoundedIcon fontSize="small" />}>
              Adicionar lista
            </Button>
          ) : (
            <>
              <OutlinedInput
                fullWidth
                placeholder="Insira um título"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
                sx={{
                  borderRadius: '8px',
                  backgroundColor: '#fff',
                  '& .MuiInputBase-input': {
                    padding: '8px 16px'
                  }
                }} />
              <Box sx={{
                display: 'flex',
                marginTop: 2
              }}>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={(e) => {
                    handleAddColumn(title);
                    setTitle('');
                    setShowForm(false);
                  }}
                  startIcon={<AddRoundedIcon fontSize="small" />}
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none'
                  }}>
                  Adicionar lista
                </Button>
                <Button
                  disableElevation
                  onClick={(e) => { setShowForm(false); }}
                  sx={{
                    marginLeft: 2,
                    borderRadius: '8px',
                    textTransform: 'none',
                    color: '#6a6a6a'
                  }}>Cancelar</Button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AddColumnButton;