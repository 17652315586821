import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import styles from '../../styles/navigation/BackButton.module.css';

const BackButton = (props) => {

  const { label, onClick } = props;

  return (
    <div className={styles.navigation}>
      <button className={styles.btnBack} onClick={onClick}>
        <ArrowBackIcon fontSize="small" />&nbsp; {label}
      </button>
    </div>
  );
};

export default BackButton;