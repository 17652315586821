import React, { useState } from 'react';

import { Box, Checkbox, Divider, IconButton, Input } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const ChecklistItem = ({ el, ind, handleChange, handleRemove }) => {
  const [title, setTitle] = useState(el.title);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', padding: '8px 24px' }}>
        <Checkbox
          edge="start"
          checked={el.checked}
          onChange={(e) => {
            handleChange({ ...el, checked: e.target.checked }, ind);
          }} />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1
        }}>
          <Input
            disableUnderline
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onBlur={(e) => {
              handleChange({ ...el, title }, ind);
            }}
            //className={styles.checkListInput}
            sx={{
              borderColor: 'transparent',
              borderRadius: 2,
              '& .MuiInputBase-input': {
                padding: '8px 16px'
              },
              ':hover': {
                textDecoration: 'none',
                backgroundColor: '#ebebeb',
                transition: [
                  'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                ]
              },
              '& .MuiInputBase-input:focus': {
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: 2
              }
            }} />
          <IconButton size="small" onClick={(e) => { handleRemove(el, ind); }}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </div>
      <Divider />
    </>
  );
};

export default ChecklistItem;