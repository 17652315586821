import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Container,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import styles from '../styles/auth/Login.module.css';
import HOST from '../components/app/host';

function ChangePasswordPage(props) {
  const [passwordInput, setPasswordInput] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const submitHandler = async (event) => {
    event.preventDefault();

    const password = passwordInput;
    const password2 = passwordInput;

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${props.token}`,
      },
      body: JSON.stringify({
        password,
        password2,
      }),
    };

    await fetch(
      `${HOST}/api/v1/accounts/change-password/${props.userId}/`,
      requestOptions
    );

    window.location.replace('/');
  };

  const passwordHandler = async (event) => {
    const password = event.target.value;
    setPasswordInput(password);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container
      maxWidth="xl"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: '#F9FAFC',
      }}
    >
      <h2
        style={{
          textAlign: 'center',
          fontSize: 30,
          marginBottom: 20,
        }}
      >
        Bem-vindo ao sistema ToGov!
      </h2>
      <h3 style={{ textAlign: 'center' }}>
        Escolha uma nova senha segura
      </h3>
      <Box
        component="form"
        sx={{
          marginTop: 2,
          padding: 2,
          boxShadow: 1,
          borderRadius: 1,
          maxWidth: 600,
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={submitHandler}
      >
        <TextField
          id="password"
          name="password"
          label="Nova Senha"
          type={showPassword ? 'text' : 'password'}
          className={styles.input}
          onChange={passwordHandler}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <div>
          <Button type="submit" variant="contained">
            Salvar
          </Button>
        </div>
      </Box>
    </Container>
  );
}

export default ChangePasswordPage;
