import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useToken from '../app/useToken';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  move,
  reorder,
  getItems,
  createColumn,
  updateColumn,
  removeColumn,
  createCard,
  updateCard,
  createChecklistItem,
  updateChecklistItem,
  removeChecklistItem,
  removeCard
} from './kanban-functions';

import Column from './Column';
import AddColumnButton from './AddColumnButton';

import { Box } from '@mui/material';
import Modal from './Modal';
import ColumnMenu from './ColumnMenu';

import styles from '../../styles/kanban/Board.module.css';


const Board = ({ data }) => {

  const { id } = useParams();
  const { token } = useToken();
  const [state, setState] = useState([]);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (data) {
      setState(getItems(data));
    }
  }, [data]);

  /* Modal */
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /* Modal */

  /* Column Menu */
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickMenu = (event, column) => {
    setSelectedColumn(column);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const openMenu = Boolean(anchorEl);
  const idColMenu = openMenu ? 'simple-popover' : undefined;
  /* Column Menu */

  async function onDragEnd(result) {
    const { source, destination, type } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (type === 'COLUMN') {
      const items = reorder(state, source.index, destination.index);

      const payload = {
        id: state[source.index].id,
        board: state[source.index].board,
        position: destination.index
      };
      await updateColumn(token, payload);
      setState(items);

      return;
    }

    if (sInd === dInd) {
      const colIndex = state.findIndex((col => col.id == sInd));
      const items = reorder(state[colIndex].cards, source.index, destination.index);
      const newState = [...state];
      newState[colIndex].cards = items;

      const payload = {
        id: items[destination.index].id,
        column: items[destination.index].column,
        position: destination.index
      };
      await updateCard(token, payload);

      setState(newState);
    } else {
      const colSourceIndex = state.findIndex((col => col.id == sInd));
      const colDestinationIndex = state.findIndex((col => col.id == dInd));
      const result = move(state[colSourceIndex], state[colDestinationIndex], source, destination);
      const newState = [...state];
      newState[colSourceIndex].cards = result[sInd];
      newState[colDestinationIndex].cards = result[dInd];

      const payload = {
        id: result[destination.droppableId][destination.index].id,
        column: state[colDestinationIndex].id,
        position: destination.index
      };
      await updateCard(token, payload);

      setState(newState);
    }
  }

  const handleAddColumn = async (title) => {
    const column = await createColumn(
      token,
      {
        board: id,
        title,
        position: (state.length > 0 ?
          (state[state.length - 1].position + 1) :
          0)
      });
    setState([...state, {
      ...column,
      cards: []
    }]);
  };

  const handleRenameColumn = async (ind, title) => {
    const column = state[ind];
    await updateColumn(
      token,
      {
        id: column.id,
        board: column.board,
        title
      });

    setState(state => {
      state[ind].title = title;
      return state;
    });
  };

  const handleClearColumn = async () => {
    handleCloseMenu();
    await updateColumn(
      token,
      {
        id: selectedColumn.id,
        board: selectedColumn.board,
        cards: []
      });
    const colSourceIndex = state.findIndex((col => col.id == selectedColumn.id));
    const newState = [...state];
    newState[colSourceIndex].cards = [];
    setState(newState);
    setSelectedColumn(null);
  };

  const handleRemoveColumn = async () => {
    handleCloseMenu();
    await removeColumn(token, selectedColumn);
    const colSourceIndex = state.findIndex((col => col.id == selectedColumn.id));
    const newState = [...state];
    newState.splice(colSourceIndex, 1);
    setState(newState);
    setSelectedColumn(null);
  };

  const handleClickCard = (item) => {
    setSelectedCard(item);
    handleClickOpen();
  };

  const handleAddCard = async (columnIndex, title) => {
    const column = state[columnIndex];
    const card = await createCard(
      token,
      {
        column: column.id,
        title,
        position: (column.cards.length > 0 ?
          (column.cards[column.cards.length - 1].position + 1) :
          0)
      });

    setState(state => {
      return state.map((val, index) => {
        if (index === columnIndex)
          return {
            ...val,
            cards: [...val.cards, {
              ...card,
              id: `${card.id}`
            }]
          };
        else return val;
      });
    });
  };

  const handleChangeCard = async (card) => {
    const colIndex = state.findIndex((col => col.id == card.column));
    const cardIndex = state[colIndex].cards.findIndex((val => val.id == card.id));
    const payload = {
      id: card.id,
      column: card.column,
      position: card.position,
      title: card.title,
      description: card.description,
      start_date: card.start_date,
      end_date: card.end_date
    };
    const result = await updateCard(token, payload);
    const newState = [...state];
    const { checklist } = newState[colIndex].cards[cardIndex];
    newState[colIndex].cards[cardIndex] = { ...result, id: `${result.id}`, checklist };
    setState(newState);
    setSelectedCard({ ...result, id: `${result.id}`, checklist });
  };

  const handleRemoveCard = async (card) => {
    handleClose();
    await removeCard(token, card);
    const colIndex = state.findIndex((col => col.id == card.column));
    const cardIndex = state[colIndex].cards.findIndex((val => val.id == card.id));
    const newState = [...state];
    newState[colIndex].cards.splice(cardIndex, 1);
    setState(newState);
    setSelectedCard(null);
  };

  const handleAddChecklistItem = async (card, title) => {
    const checkItem = await createChecklistItem(
      token,
      {
        card: card.id,
        title,
        position: (card.checklist.length > 0 ?
          (card.checklist[card.checklist.length - 1].position + 1) :
          0)
      });

    const colIndex = state.findIndex((col => col.id == card.column));
    const cardIndex = state[colIndex].cards.findIndex((val => val.id == card.id));
    const newState = [...state];
    newState[colIndex].cards[cardIndex].checklist = [...newState[colIndex].cards[cardIndex].checklist, checkItem];
    setState(newState);
    setSelectedCard({ ...newState[colIndex].cards[cardIndex], checklist: [...newState[colIndex].cards[cardIndex].checklist] });
  };

  const handleChangeChecklist = async (card, item, index) => {
    const payload = {
      id: item.id,
      card: item.card,
      title: item.title,
      position: item.position,
      checked: item.checked
    };
    const checkItem = await updateChecklistItem(token, payload);
    const colIndex = state.findIndex(col => col.id == card.column);
    const cardIndex = state[colIndex].cards.findIndex(val => val.id == card.id);
    const newState = [...state];
    newState[colIndex].cards[cardIndex].checklist[index] = checkItem;
    setState(newState);
    setSelectedCard({ ...newState[colIndex].cards[cardIndex], checklist: [...newState[colIndex].cards[cardIndex].checklist] });
  };

  const handleRemoveChecklist = async (card, item, index) => {
    const checkItem = await removeChecklistItem(token, item);
    const colIndex = state.findIndex(col => col.id == card.column);
    const cardIndex = state[colIndex].cards.findIndex(val => val.id == card.id);
    const newState = [...state];
    newState[colIndex].cards[cardIndex].checklist = newState[colIndex].cards[cardIndex].checklist.filter(val => val.id != item.id);
    setState(newState);
    setSelectedCard({ ...newState[colIndex].cards[cardIndex], checklist: [...newState[colIndex].cards[cardIndex].checklist] });
  };

  return (
    <>
      <Box className={styles.board} sx={{ minHeight: '91vh' }}>
        <Box className={styles.columnsWrapper}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="board"
              type="COLUMN"
              direction="horizontal">
              {(provided) => (
                <Box sx={{ display: 'flex' }}
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {state && state.map((el, ind) => (
                    <Column
                      key={el.id}
                      el={el}
                      ind={ind}
                      handleRename={handleRenameColumn}
                      // handleRemove={handleRemoveColumn}
                      handleAdd={handleAddCard}
                      handleClickMenu={handleClickMenu}
                      handleClickCard={handleClickCard} />
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <AddColumnButton handleAddColumn={handleAddColumn} />
        </Box>
      </Box>
      <Modal
        open={open}
        data={selectedCard}
        handleChangeCard={handleChangeCard}
        handleRemoveCard={handleRemoveCard}
        handleAdd={handleAddChecklistItem}
        handleChange={handleChangeChecklist}
        handleRemove={handleRemoveChecklist}
        handleClose={handleClose} />
      <ColumnMenu
        id={idColMenu}
        open={openMenu}
        anchorEl={anchorEl}
        data={selectedColumn}
        handleClose={handleCloseMenu}
        handleClear={handleClearColumn}
        handleDelete={handleRemoveColumn} />
    </>
  );
};

export default Board;