import React from 'react';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import styles from '../../styles/menu/Sidebar.module.css';


export default function ListDashboard({ openParent, departamentId, dashboards, toggleDrawer }) {
  return (
    <>
      {dashboards.map(dashboard => {
        if (dashboard.departament === departamentId)
          return (
            <Collapse
              key={dashboard.id}
              in={openParent}
              timeout="auto"
              unmountOnExit
              className={styles.listItem3}
            >
              <List component="div" disablePadding>
                <Link to={`paineis/${dashboard.id}`} onClick={toggleDrawer(false)}>
                  <ListItemButton sx={{ pl: 4 }}>
                    <ListItemText primary={dashboard.title} className={styles.listItemText} />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          );
      })}
    </>
  );
}