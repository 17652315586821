import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useToken from '../app/useToken';
import HOST from '../app/host';

import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

import ListGeneralMobile from './ListGeneralMobile';
import ListDashboardMobile from './ListDashboardMobile';
import ListExternalitiesMobile from './ListExternalitiesMobile';
import ListBoardsMobile from './ListBoardsMobile';

import styles from '../../styles/menu/SidebarMobile.module.css';

export default function SidebarMobile({ user, state, toggleDrawer }) {
  const navigate = useNavigate();
  const { token } = useToken();
  const { dashboards, departaments, boards, is_manager } = user;
  const [checked, setChecked] = useState(false);
  const [externalities, setExternalities] = useState([]);
  const [showBudget, setShowBudget] = useState(true);
  const [openX, setOpenX] = useState(false);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    fetch(`${HOST}/api/companys/${user.company}/`, requestOptions)
      .then(response => response.json())
      .then(data => {
        const value = [];
        data.externalities_dashboards.forEach(element => {
          fetch(`${HOST}/api/v1/externalities/${element}/`, requestOptions)
            .then(response => response.json())
            .then(data => value.push(data));
        });
        setExternalities(value);
      });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setOpenX(true);
    }, 1000);
  }, [openX]);

  return (
    <Drawer
      open={state}
      onClose={toggleDrawer(false)}
      className={styles.drawer}
    >
      <Box className={styles.sidebar} role='presentation'>
        <Box
          className={user.logo_bg_white ? styles.logoBoxWhite : styles.logoBox}
        >
          <img src={`/media/${user.logo_url}`} width='100%' />
        </Box>

        <Typography
          component='p'
          variant='span'
          fontSize={13}
          className={styles.listTitle}
        >
          geral
        </Typography>

        <ListGeneralMobile
          toggleDrawer={toggleDrawer}
          showPaineis={true}
          handleClick={e => {
            () => {};
          }}
          checked={checked}
          handleChecked={e => {
            toggleDrawer(false)(e);
            if (!checked) {
              setChecked(state => !state);
              navigate('/exibicao-paineis');
            } else {
              setChecked(state => !state);
              navigate('/');
            }
          }}
          isManager={is_manager}
        />

        <Divider />

        <Typography
          component='p'
          variant='span'
          fontSize={13}
          className={styles.listTitle}
        >
          Painéis
        </Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <ListDashboardMobile
            openParent={true}
            dashboards={dashboards}
            toggleDrawer={toggleDrawer}
          />
        </Box>

        <Divider />

        <Typography
          component='p'
          variant='span'
          fontSize={13}
          className={styles.listTitle}
        >
          Externalidades
        </Typography>

        {user.show_tax_requirements ? (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <ListExternalitiesMobile
              openParent={true}
              items={externalities}
              toggleDrawer={toggleDrawer}
            />
          </Box>
        ) : (
          <Link to='/contratacao' onClick={toggleDrawer(false)}>
            <ListItem
              key={'Externalidades'}
              disablePadding
              className={styles.listItem}
              sx={{ width: 165 }}
            >
              <ListItemButton>
                <div className={styles.listItemBtn}>
                  <ListItemIcon>
                    <PriorityHighRoundedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Externalidades'}
                    className={styles.listItemText}
                    sx={{ marginLeft: '-5px' }}
                  />
                </div>
              </ListItemButton>
            </ListItem>
          </Link>
        )}

        <Divider />

        <Typography
          component='p'
          variant='span'
          fontSize={13}
          className={styles.listTitle}
        >
          Quadros
        </Typography>

        {boards.length > 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <ListBoardsMobile
              boards={boards}
              openParent={true}
              toggleDrawer={toggleDrawer}
            />
          </Box>
        )}

        <Divider />

        <Typography
          component='p'
          variant='span'
          fontSize={13}
          className={styles.listTitle}
        >
          Orçamento
        </Typography>

        {showBudget ? (
          <Link to='/orcamento' onClick={toggleDrawer(false)}>
            <ListItem
              key={'Orçamento'}
              disablePadding
              className={styles.listItem}
              sx={{ width: 165 }}
            >
              <ListItemButton>
                <div className={styles.listItemBtn}>
                  <ListItemIcon>
                    <PriceCheckIcon sx={{ fontSize: '33px !important' }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Orçamento'}
                    className={styles.listItemText}
                  />
                </div>
              </ListItemButton>
            </ListItem>
          </Link>
        ) : (
          <Link to='/contratacao' onClick={toggleDrawer(false)}>
            <ListItem
              key={'Orçamento'}
              disablePadding
              className={styles.listItem}
            >
              <ListItemButton>
                <div className={styles.listItemBtn}>
                  <ListItemIcon>
                    <PriceCheckIcon sx={{ fontSize: 27.5 }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Orçamento'}
                    className={styles.listItemText}
                  />
                </div>
              </ListItemButton>
            </ListItem>
          </Link>
        )}
      </Box>
    </Drawer>
  );
}
