import React, { useState } from 'react';
import HOST from '../app/host';

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import styles from '../../styles/auth/Login.module.css';

async function loginUser(credentials) {
  const username = credentials.email;
  const password = credentials.password;

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username,
      password
    })
  };

  return fetch(`${HOST}/api/accounts/login/`, requestOptions).then(
    response => response.ok && response.json()
  );
}

export default function Login({ setToken }) {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const handleCredentials = async e => {
    setError(false);
    const { name, value } = await e.target;
    setCredentials({
      ...credentials,
      [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser(credentials);
    token ? setToken(token) : setError(true);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  document.title = 'ToGov | Login';
  return (
    <div className={styles.loginPage} onSubmit={handleSubmit}>
      <Box component='form' className={styles.loginForm} autoComplete='off'>
        <img src='/media/logo_images/logotipo-togov.png' />

        <TextField
          name='email'
          id='email'
          label='E-mail'
          type='text'
          error={error}
          className={styles.input}
          onChange={handleCredentials}
          fullWidth
        />

        <TextField
          name='password'
          id='password'
          label='Senha'
          type={showPassword ? 'text' : 'password'}
          error={error}
          className={styles.input}
          onChange={handleCredentials}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <Button
          className={styles.btnEntrar}
          variant='contained'
          type='submit'
          fullWidth
        >
          Entrar
        </Button>

        <a
          href={`${HOST}/api/reset_password/`}
          className={styles.resetPassword}
        >
          Esqueceu a senha?
        </a>
      </Box>
    </div>
  );
}
