import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

import Box from '@mui/material/Box';
import { Paper, Typography } from '@mui/material';

const Card = ({ ind, item, index, handleClick }) => {
  return (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
    >
      {(provided, snapshot) => (
        <Box
          sx={{
            outline: 'none',
            paddingTop: 1,
            paddingBottom: 1
          }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          <Paper className="MuiCard-root"
            variant="elevation"
            elevation={1}
            sx={{
              backgroundColor: '#fff',
              color: '#000',
              transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              borderRadius: 2,
              boxShadow: 'rgb(0 0 0 / 24%) 0px 1px 2px',
              backgroundImage: 'none',
              overflow: 'hidden'
            }} onClick={(e) => {
              handleClick(item);
            }}>
            <Box
              sx={{
                padding: 3
              }}>
              <Typography
                variant="subtitle1"
                component="h6"
                sx={{
                  fontWeight: 600
                }}>
                {item.title} {/*`key: ${item.id} draggableId: ${item.id} index: ${index}`*/}
              </Typography>
            </Box>
          </Paper>
        </Box>
      )}
    </Draggable>
  );
};

export default Card;