import React, { useEffect, useState } from 'react';

import ListDashboard from './ListDashboard';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import styles from '../../styles/menu/Sidebar.module.css';

export default function ListDepartament({
  openParent,
  departaments,
  dashboards,
  toggleDrawer
}) {
  const [itemList, setItemList] = useState(departaments);

  useEffect(() => {
    itemList.map(item => {
      item['open'] = false;
    });
  }, [null]);

  const handleClick = id => {
    itemList.map(item => {
      if (item.id === id) {
        item['open'] = !item['open'];
      } else {
        item['open'] = false;
      }
    });
    setItemList([...itemList]);
  };

  return (
    <>
      {itemList.map(item => (
        <Collapse
          key={item.id}
          in={openParent}
          timeout="auto"
          unmountOnExit
          className={styles.listItem2}
        >
          <List component="div" disablePadding>
            <ListItemButton onClick={() => handleClick(item.id)}>
              <ListItemText
                primary={item.name}
                className={styles.listItemText}
              />
            </ListItemButton>
          </List>

          <ListDashboard
            openParent={item.open}
            departamentId={item.id}
            dashboards={dashboards}
            toggleDrawer={toggleDrawer}
          />
        </Collapse>
      ))}
    </>
  );
}
