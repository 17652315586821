import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import styles from '../styles/pages/Loading.module.css';


export default function Loading() {
  return (
    <Box className={styles.loadingPage}>
      <CircularProgress />
    </Box>
  );
}