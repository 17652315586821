import React, { useState } from 'react';
import axios from 'axios';
import HOST from '../components/app/host';
import useToken from '../components/app/useToken';
import AnalytcsCard from '../components/AnalyticsCard';
import Banner from '../components/Banner';
import styles from '../styles/pages/Home.module.css';
import { useEffect } from 'react';


function Home() {
  const { token, setToken } = useToken();
  const [analytcs, setAnalytcs] = useState({});

  useEffect(() => {
    const requestConfig = {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    };

    axios.get(`${HOST}/api/v1/accounts/1/statistics/`, requestConfig)
      .then(response => setAnalytcs(response.data));
  }, []);


  return (
    <div className={styles.container}>
      <Banner />

      <div className={styles.cards}>
        <AnalytcsCard
          title="Quantidade de usuários"
          value={analytcs.number_of_users}
        />
        <AnalytcsCard
          title="Quantidade de painéis"
          value={analytcs.number_of_panels}
        />
        <AnalytcsCard
          title="Projetos monitorados"
          value={analytcs.number_of_projects}
        />
        <AnalytcsCard
          title="Data do último acesso"
          value={analytcs.last_login}
        />
      </div>
    </div>
  );
}

export default Home;
