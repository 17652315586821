import React from 'react';
import { List, MenuItem } from '@mui/material';
import Popover from '@mui/material/Popover';

const ColumnMenu = ({ id, open, anchorEl, handleClose, handleRename, handleClear, handleDelete }) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <List>
        {/* <MenuItem onClick={(e) => { handleRename(id) }}>Renomear</MenuItem> */}
        <MenuItem onClick={(e) => { handleClear(); }}>Limpar</MenuItem>
        <MenuItem onClick={(e) => { handleDelete(); }}>Apagar</MenuItem>
      </List>
    </Popover>
  );
};

export default ColumnMenu;