import HOST from '../app/host';

export const getItems = ({ columns }) => {
  if (columns)
    return columns.map((val, index) => {
      return {
        ...val,
        cards: val.cards.map((val, index) => {
          return {
            ...val,
            id: `${val.id}`
          };
        })
      };
    });
  else return [];
};

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
export const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source.cards);
  const destClone = Array.from(destination.cards);

  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

export const createColumn = async (token, { board, title, position }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      board,
      title,
      position,
      cards: []
    })
  };

  const response = await fetch(`${HOST}/api/v1/kanban/columns/`, requestOptions);
  const data = await response.json();
  return data;
};

export const updateColumn = async (token, column) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(column)
  };

  const response = await fetch(`${HOST}/api/v1/kanban/columns/${column.id}`, requestOptions);
  const data = await response.json();
  return data;
};

export const removeColumn = async (token, column) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(column)
  };

  const response = await fetch(`${HOST}/api/v1/kanban/columns/${column.id}`, requestOptions);
  const data = await response.text();
  return data;
};

export const createCard = async (token, { column, title, position }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      column,
      title,
      position
    })
  };

  const response = await fetch(`${HOST}/api/v1/kanban/cards/`, requestOptions);
  const data = await response.json();
  data.checklist = [];
  return data;
};

export const updateCard = async (token, card) => {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(card)
  };

  const response = await fetch(`${HOST}/api/v1/kanban/cards/${card.id}/`, requestOptions);
  const data = await response.json();
  return data;
};

export const removeCard = async (token, card) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(card)
  };

  const response = await fetch(`${HOST}/api/v1/kanban/cards/${card.id}/`, requestOptions);
  const data = await response.text();
  return data;
};

export const createChecklistItem = async (token, { card, title, position }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      card,
      title,
      position
    })
  };

  const response = await fetch(`${HOST}/api/v1/kanban/checklist/`, requestOptions);
  const data = await response.json();
  return data;
};

export const updateChecklistItem = async (token, checkItem) => {
  console.log(checkItem);
  const requestOptions = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(checkItem)
  };

  const response = await fetch(`${HOST}/api/v1/kanban/checklist/${checkItem.id}/`, requestOptions);
  const data = await response.json();
  return data;
};

export const removeChecklistItem = async (token, checkItem) => {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(checkItem)
  };

  const response = await fetch(`${HOST}/api/v1/kanban/checklist/${checkItem.id}/`, requestOptions);
  const data = await response.text();
  return data;
};
