import React from 'react';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import styles from '../../styles/menu/Sidebar.module.css';

export default function ListGeneralMobile({ toggleDrawer, isManager }) {
  return (
    <List className={styles.smallList}>
      <Link to='/' onClick={toggleDrawer(false)}>
        <ListItem key={'Home'} disablePadding>
          <ListItemButton className={styles.listItemBtn}>
            <ListItemIcon>
              <HomeIcon sx={{ fontSize: 26 }} />
            </ListItemIcon>
            <ListItemText primary={'Home'} className={styles.listItemText} />
          </ListItemButton>
        </ListItem>
      </Link>

      {isManager && (
        <>
          <Link to='/administrativo' onClick={toggleDrawer(false)}>
            <ListItem key={'Admin'} disablePadding>
              <ListItemButton className={styles.listItemBtn}>
                <ListItemIcon>
                  <SupervisorAccountIcon sx={{ fontSize: 28 }} />
                </ListItemIcon>
                <ListItemText
                  primary={'Admin'}
                  className={styles.listItemText}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        </>
      )}
    </List>
  );
}
