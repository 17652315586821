import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HOST from '../components/app/host';
import useToken from '../components/app/useToken';

import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';

import styles from '../styles/pages/Admin.module.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export default function Admin() {
  const navigate = useNavigate();

  const { token } = useToken();

  const [users, setUsers] = useState([]);

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    fetch(`${HOST}/api/accounts/`, requestOptions)
      .then(response => response.json())
      .then(data => setUsers(data.filter(user => user)));
  }, []);

  return (
    <Container maxWidth='xl'>
      <div className={styles.titleWrapper}>
        <h2>Usuários</h2>
        <Button
          variant='contained'
          onClick={e => {
            navigate('/administrativo/usuarios/adicionar');
          }}
        >
          <AddIcon />
          &nbsp; Adicionar
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
          <TableHead>
            <TableRow>
              <StyledTableCell>Nome</StyledTableCell>
              <StyledTableCell>E-mail</StyledTableCell>
              <StyledTableCell>Editar</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(user => (
              <TableRow
                hover
                key={user.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{`${user.first_name} ${user.last_name}`}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <Button
                    onClick={e => {
                      navigate(`/administrativo/usuarios/editar/${user.id}`);
                    }}
                  >
                    <EditIcon cursor='pointer' />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
