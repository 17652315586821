import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import HOST from '../components/app/host';
import useToken from '../components/app/useToken';

import ListDepartment from '../components/form-users/ListDepartament';
import BackButton from '../components/navigation/BackButton';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';

import styles from '../styles/pages/AddUser.module.css';

const getAuthUser = token => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  };

  return fetch(`${HOST}/api/accounts/auth/`, requestOptions)
    .then(response => response.json())
    .then(user => user);
};

export default function AddUser() {

  const navigate = useNavigate();

  const { token } = useToken();

  const [dashboards, setDashboards] = useState([]);

  const [departaments, setDepartaments] = useState([]);

  const [user, setUser] = useState({ first_name: '', last_name: '', email: '', password: '' });

  useEffect(async () => {
    if (token) {
      const authUser = await getAuthUser(token);

      setDepartaments(authUser.departaments);

      const dashs = authUser.dashboards.map((dash) => { return { ...dash, checked: false }; });
      setDashboards(dashs);
    }
  }, [token]);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleChangeCheckbox = (index, checked) => {
    setDashboards(state => {
      const temp = [...state];

      temp[index].checked = !checked;

      return temp;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const dashs = dashboards.filter(dash => {
      if (dash.checked === true) return dash.id;
    }).map(dash => dash.id);

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        username: user.email,
        password: user.password,
        first_name: user.first_name,
        last_name: user.last_name,
        dashboards: dashs
      })
    };

    fetch(`${HOST}/api/accounts/`, requestOptions)
      .then(response => response.json())
      .then(data => {
        navigate('/administrativo');
      });
  };

  return (
    <Container maxWidth="xl">
      <BackButton label="Usuários" onClick={(e) => { navigate('/administrativo'); }} />
      <h2>Adicionar Usuário</h2>
      <Box
        component="form"
        sx={{
          marginTop: 1,
          padding: 2,
          boxShadow: 1,
          borderRadius: 1,
          maxWidth: 600,
          '& > :not(style)': { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <FormControl sx={{ width: '47%' }}>
          <InputLabel htmlFor="name">Nome</InputLabel>
          <OutlinedInput
            id="name"
            name="first_name"
            value={user.first_name}
            onChange={handleChangeInput}
            label="Nome"
          />
        </FormControl>
        <FormControl sx={{ width: '47%' }}>
          <InputLabel htmlFor="lastName">Sobrenome</InputLabel>
          <OutlinedInput
            id="lastName"
            name="last_name"
            value={user.last_name}
            onChange={handleChangeInput}
            label="Sobrenome"
          />
        </FormControl>
        <FormControl sx={{ width: '47%' }}>
          <InputLabel htmlFor="email">E-mail</InputLabel>
          <OutlinedInput
            id="email"
            name="email"
            value={user.email}
            onChange={handleChangeInput}
            label="E-mail"
          />
        </FormControl>
        <FormControl sx={{ width: '47%' }}>
          <InputLabel htmlFor="password">Senha</InputLabel>
          <OutlinedInput
            id="password"
            type="password"
            name="password"
            value={user.password}
            onChange={handleChangeInput}
            label="Senha"
          />
        </FormControl>

        <h3>Departamentos</h3>

        <ListDepartment departaments={departaments} dashboards={dashboards} onChange={handleChangeCheckbox} />

        <div className={styles.saveBtnWrapper}>
          <Button type="submit" variant="contained">
            Salvar
          </Button>
        </div>

      </Box>
    </Container>
  );
}
