/* eslint-disable no-undef */
/* eslint-disable indent */
let HOST = '';

switch (process.env.NODE_ENV) {
  case 'development':
    HOST = 'http://localhost:8000';
    break;
  case 'production':
    HOST = process.env.REACT_APP_API_SERVER;
    break;
  default:
    HOST = 'http://localhost:8000';
    break;
}

export default HOST;
