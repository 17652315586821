import React from 'react';
import styles from './styles.module.css';

function AnalytcsCard({ title, value }) {
  return (
    <div className={styles.card}>
      <div className={styles.content}>
        <span>{title}</span>
        <span>{value}</span>
      </div>
      <div>
        <img src='graphic.png' alt='gráfico' width='125px' />
      </div>
    </div>
  );
}

export default AnalytcsCard;
