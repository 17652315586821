import React from 'react';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';

import styles from '../../styles/menu/Sidebar.module.css';

const ListBoardsMobile = ({ boards, openParent, toggleDrawer }) => {
  if (Array.isArray(boards))
    return boards.map(item => (
      <Collapse
        key={item.id}
        in={openParent}
        timeout="auto"
        unmountOnExit
              
      >
        <List component="div" disablePadding className={styles.smallList}>
          <Link to={`quadros/${item.id}`} onClick={toggleDrawer(false)} className={styles.listItem2SmallListBtn}>
            <ListItemButton className={styles.listItemBtn}>
              <ListItemText primary={item.title} className={styles.listItemText} />
            </ListItemButton>
          </Link>
        </List>
      </Collapse>
    ));
  else return null;

};

export default ListBoardsMobile;