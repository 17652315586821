import HOST from './host';

const getAuthUser = token => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(`${HOST}/api/accounts/auth/`, requestOptions)
    .then(response => response.json())
    .then(user => user);
};

export default getAuthUser;
