import React, { useEffect, useState } from 'react';
import { Box, Grid, InputBase, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';

import styles from '../../styles/kanban/Modal.module.css';
import CardChecklist from './CardChecklist';

const CustomInput = ({ label, value, handleChangeCard, options }) => {
  const [text, setText] = useState('');

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <TextField
      label={label}
      type="text"
      fullWidth
      variant="outlined"
      value={text ? text : ''}
      onChange={(e) => setText(e.target.value)}
      onBlur={(e) => { handleChangeCard(text); }}
      {...options}
    />
  );
};

const CustomDatePicker = ({ value, handleChangeCard }) => {
  const [date, setDate] = useState('');

  useEffect(() => {
    setDate(value);
  }, [value]);

  const formatDate = (date) => {
    // eslint-disable-next-line no-useless-escape
    return date.replace(/\:\d\d\-\d\d\:\d\d/, '');
  };

  return (
    <InputBase
      type="datetime-local"
      fullWidth
      value={formatDate(date ? date : '')}
      onChange={(e) => setDate(e.target.value)}
      onBlur={(e) => { handleChangeCard(`${date}:00-03:00`); }}
      sx={{
        backgroundColor: '#dddee5',
        borderRadius: 2,
        padding: '8px 20px',
        ':hover': {
          textDecoration: 'none',
          backgroundColor: '#ccccd3',
          transition: [
            'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          ]
        }
      }} />
  );
};

const Modal = ({
  open,
  data,
  handleChangeCard,
  handleRemoveCard,
  handleAdd,
  handleChange,
  handleRemove,
  handleClose
}) => {

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        className: styles.modal
      }}
      PaperComponent={(props) => <Paper {...props} />}
    >
      <Grid container>
        <Grid item xs={12} sm={8}>
          <Box sx={{ padding: '32px 24px' }}>
            <CustomInput
              label="Título"
              value={data && data.title ? data.title : ''}
              handleChangeCard={(title) => {
                handleChangeCard({ ...data, title });
              }} />

            <CustomInput
              label="Descrição"
              value={data && data.description ? data.description : ''}
              handleChangeCard={(description) => {
                handleChangeCard({ ...data, description });
              }}
              options={{ multiline: true, inputProps: { style: { height: '138px' } }, sx: { margin: '24px 0 0' } }} />

            {data && data.checklist ? (
              <CardChecklist
                card={data}
                tasks={data.checklist}
                handleAdd={handleAdd}
                handleRemove={handleRemove}
                handleChange={handleChange} />
            ) : null}
          </Box>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Box sx={{
            backgroundColor: '#EBECF0',
            padding: '32px 24px',
            height: '100%'
          }}>
            <Box sx={{
              marginTop: 2
            }}>
              <Typography
                variant="overline"
                sx={{
                  fontWeight: 600
                }}>Data de início</Typography>
              <Box sx={{
                marginTop: 2
              }}>
                <CustomDatePicker
                  value={data && data.start_date ? data.start_date : ''}
                  handleChangeCard={(start_date) => {
                    handleChangeCard({ ...data, start_date });
                  }} />
              </Box>
            </Box>
            <Box sx={{
              marginTop: 2
            }}>
              <Typography
                variant="overline"
                sx={{
                  fontWeight: 600
                }}>Data de entrega</Typography>
              <Box sx={{
                marginTop: 2
              }}>
                <CustomDatePicker
                  value={data && data.end_date ? data.end_date : ''}
                  handleChangeCard={(end_date) => {
                    handleChangeCard({ ...data, end_date });
                  }} />
              </Box>
            </Box>
            <Box sx={{
              marginTop: 2
            }}>
              <Typography
                variant="overline"
                sx={{
                  fontWeight: 600
                }}>Ações</Typography>
              <Box sx={{
                marginTop: 2
              }}>
                <Button
                  variant="contained"
                  size="medium"
                  disableElevation
                  fullWidth
                  onClick={(e) => { handleRemoveCard(data); }}
                  sx={{
                    backgroundColor: '#dc3545',
                    borderRadius: 2,
                    textTransform: 'none',
                    padding: '8px 20px',
                    color: '#fff',
                    justifyContent: 'flex-start',
                    ':hover': {
                      textDecoration: 'none',
                      backgroundColor: '#d30014',
                      transition: [
                        'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        'border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                      ]
                    }
                  }}>
                  Excluir cartão
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;