import React from 'react';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import HomeIcon from '@mui/icons-material/Home';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SlideshowRoundedIcon from '@mui/icons-material/SlideshowRounded';

import styles from '../../styles/menu/Sidebar.module.css';
import { Collapse, Switch } from '@mui/material';

export default function ListGeneral({
  toggleDrawer,
  showPaineis,
  handleClick,
  checked,
  handleChecked,
  isManager
}) {
  return (
    <List className={styles.smallList}>
      <Link to='/' onClick={toggleDrawer(false)}>
        <ListItem key={'Home'} disablePadding>
          <ListItemButton className={styles.listItemBtn}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Home'} className={styles.listItemText} />
          </ListItemButton>
        </ListItem>
      </Link>

      {isManager && (
        <>
          <Link to='/administrativo' onClick={toggleDrawer(false)}>
            <ListItem key={'Admin'} disablePadding>
              <ListItemButton className={styles.listItemBtn}>
                <ListItemIcon>
                  <SupervisorAccountIcon />
                </ListItemIcon>
                <ListItemText
                  primary={'Admin'}
                  className={styles.listItemText}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        </>
      )}

      <ListItem
        key={'Exibição de painéis'}
        disablePadding
        onClick={e => handleClick()}
      >
        <ListItemButton className={styles.listItemBtn}>
          <ListItemIcon>
            <SlideshowRoundedIcon />
          </ListItemIcon>
          <ListItemText
            primary={'Exibição de painéis'}
            className={styles.listItemText}
          />
        </ListItemButton>
      </ListItem>

      <Collapse
        key={1}
        in={showPaineis}
        timeout='auto'
        unmountOnExit
        className={styles.listItem2}
      >
        <List
          component='div'
          disablePadding
          className={styles.listItem2SmallList}
        >
          <ListItemButton
            disableRipple
            disableTouchRipple
            className={styles.listItem2SmallListBtn}
          >
            <ListItemText
              primary={'Mudança automática:'}
              className={styles.listItemText}
            />
            <Switch checked={checked} onChange={handleChecked} />
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
