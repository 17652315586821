import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const ListDepartment = (props) => {
  const { departaments, dashboards, onChange } = props;

  // console.log(departaments, dashboards)

  if (departaments)
    return departaments.map((department, index) => {
      return (
        <FormControl key={index} sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel component="legend">{department.name}</FormLabel>
          <FormGroup>
            {dashboards && dashboards.map((dashboard, index) => {
              if (dashboard.departament === department.id)
                return (
                  <FormControlLabel key={index}
                    control={
                      <Checkbox
                        key={index}
                        name={`dashboard${dashboard.id}`}
                        checked={dashboard.checked}
                        onChange={(e) => { onChange(index, dashboard.checked); }} />
                    }
                    label={dashboard.title}
                  />
                );
            })}
          </FormGroup>
          {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
      );
    });
  else return null;
};

export default ListDepartment;