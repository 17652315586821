import React from 'react';

import Banner from '../components/Banner';
import styles from '../components/Banner/styles.module.css';

import MenuIcon from '@mui/icons-material/Menu';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


function PremiumPage() {
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <div>
          <img
            src={'/banner-image.png'}
            width="200px"
          />
        </div>

        <div className={styles.content}>
          <h1>Você não contratou este módulo ainda!</h1>
          <p>Entre em contato com nosso time comercial.</p>
          <a href="https://wa.me/553195407950" className={styles.btn}>
            <WhatsAppIcon />
            <span>Entre em contato</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PremiumPage;