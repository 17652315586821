import React from 'react';
import { Link } from 'react-router-dom';

import { Collapse } from '@mui/material';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styles from '../../styles/menu/Sidebar.module.css';

function ListExternalitiesMobile(props) {
  return (
    <>
      {props.items.map(item => {
        return (
          <Collapse
            key={item.id}
            in={props.openParent}
            timeout='auto'
            unmountOnExit
          >
            <List component='div' disablePadding className={styles.smallList}>
              <Link
                to={`externalidades/${item.id}`}
                onClick={props.toggleDrawer(false)}
                className={styles.listItem2SmallListBtn}
              >
                <ListItemButton className={styles.listItemBtn}>
                  <ListItemText
                    primary={item.title}
                    className={styles.listItemText}
                  />
                </ListItemButton>
              </Link>
            </List>
          </Collapse>
        );
      })}
    </>
  );
}

export default ListExternalitiesMobile;
