import React from 'react';
import { Box, ButtonBase, CardActions, Checkbox, Divider, IconButton, Input, InputBase, LinearProgress, Paper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import ChecklistItem from './ChecklistItem';
import AddCheckButton from './AddCheckButton';

const getPercentage = (tasks) => {
  let completed = 0;
  const qtd = tasks.length;

  if (qtd > 0) {
    tasks.forEach(val => {
      if (val.checked) completed++;
    });

    return Math.round((completed * 100) / qtd);
  }
  else return 0;
};

const CardChecklist = ({ card, tasks, handleAdd, handleChange, handleRemove }) => {

  const handleAddChecklist = (title) => {
    handleAdd(card, title);
  };

  const handleChangeChecklist = (item, index) => {
    handleChange(card, item, index);
  };

  const handleRemoveChecklist = (item, index) => {
    handleRemove(card, item, index);
  };

  return (
    <>
      <Typography variant="h6" sx={{
        margin: '24px 0'
      }}>Checklist</Typography>
      <div>
        <div style={{ marginBottom: 24 }}>
          <Paper elevation={0} variant="outlined" sx={{
            borderColor: 'rgba(0, 0, 0, 0.23)',
            overflow: 'hidden'
          }}>
            <Box sx={{ padding: 3 }}>
              <Box>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}>
                  <Typography component="span" variant="caption">{getPercentage(tasks)}%</Typography>
                  <Box sx={{ marginLeft: 2, flexGrow: 1 }}>
                    <LinearProgress
                      variant="determinate"
                      color={getPercentage(tasks) < 100 ? 'info' : 'success'}
                      value={getPercentage(tasks)}
                      sx={{
                        display: 'block',
                        height: '8px',
                        borderRadius: '8px'
                      }} />
                  </Box>
                </Box>
              </Box>
            </Box>
            <Divider />
            {tasks && tasks.map((val, index) => {
              return (
                <ChecklistItem
                  key={index}
                  el={val}
                  ind={index}
                  handleChange={handleChangeChecklist}
                  handleRemove={handleRemoveChecklist} />
              );
            })}
            <CardActions sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '16px 24px'
            }}>
              <Box sx={{
                width: '100%'
              }}>
                <AddCheckButton handleAdd={handleAddChecklist} />
              </Box>
            </CardActions>
          </Paper>
        </div>
      </div>
    </>
  );
};

export default CardChecklist;