import React, { useState, useEffect } from 'react';
import HOST from './host';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import useToken from './useToken';
import Login from '../auth/Login';
import Logout from '../auth/Logout';
import Navbar from '../menu/Navbar';
import Home from '../../pages/Home';
import Admin from '../../pages/Admin';
import Loading from '../../pages/Loading';
import Dashboard from '../../pages/Dashboard';
import AddUser from '../../pages/AddUser';
import DashboardSlide from '../../pages/DashboardSlide';
import EditUser from '../../pages/EditUser';
import Kanban from '../../pages/Kanban';
import ChangePasswordPage from '../../pages/change-password';
import PremiumPage from '../../pages/PremiumPage';
import Externalities from '../../pages/Externalities';
import BudgetPage from '../../pages/BudgetPage';

export default function App() {
  const { token, setToken } = useToken();
  const [user, setUser] = useState({});
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);

  const getCompany = companyId => {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    return fetch(`${HOST}/api/companys/${companyId}/`, requestOptions)
      .then(response => response.json())
      .then(company => company);
  };

  useEffect(async () => {
    if (token) {
      const authUser = await getAuthUser(token);
      const boards = await getBoards(token, authUser);
      const company = await getCompany(authUser.company);

      setCompany(company);
      setUser({ ...authUser, boards });
    }
    setLoading(false);
  }, [token]);

  if (!user.is_authenticated) {
    return loading ? <Loading /> : <Login setToken={setToken} />;
  }

  document.title = 'ToGov';
  if (!user.last_login) {
    return <ChangePasswordPage userId={user.id} token={token} />;
  }

  return loading ? (
    <Loading />
  ) : (
    <div className='app'>
      <BrowserRouter>
        <Navbar user={user} />
        <Routes>
          <Route path='/' element={<Home user={user} />} />
          {user.is_manager && (
            <>
              <Route path='/administrativo' element={<Admin />} />
              <Route
                path='/administrativo/usuarios/adicionar'
                element={<AddUser />}
              />
              <Route
                path='/administrativo/usuarios/editar/:id'
                element={<EditUser />}
              />
            </>
          )}
          <Route path='/logout' element={<Logout />} />
          <Route path='/paineis/:id' element={<Dashboard />} />
          <Route path='/quadros/:id' element={<Kanban />} />
          <Route path='/exibicao-paineis' element={<DashboardSlide />} />
          <Route path='/contratacao' element={<PremiumPage />} />
          <Route
            path='/alterar-senha'
            element={<ChangePasswordPage userId={user.id} token={token} />}
          />
          <Route
            path='/externalidades/:id'
            element={<Externalities companyId={user.company} />}
          />
          {company.show_budget && (
            <Route
              path='/orcamento'
              element={<BudgetPage company={company} />}
            />
          )}
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

const getAuthUser = token => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(`${HOST}/api/accounts/auth/`, requestOptions)
    .then(response => response.json())
    .then(user => user);
};

const getBoards = (token, user) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    }
  };

  return fetch(`${HOST}/api/v1/accounts/${user.id}/boards/`, requestOptions)
    .then(response => response.json())
    .then(boards => boards)
    .catch(error => error);
};
